<template>
   <el-form>
      <el-form-item>
         <el-autocomplete style="width: 100%" v-model="nickName" :fetch-suggestions="searchMembers" placeholder="请输入内容" clearable @select="selectMember" :disabled="disabled"></el-autocomplete>
      </el-form-item>
      <el-form-item>
         <p>已选择：</p>
         <el-tag v-for="tag in memberList" :key="tag.value" @close="closeTag(tag)" :closable="!disabled">{{tag.value}}</el-tag>
      </el-form-item>
   </el-form>
</template>
<script>
import { mapState } from 'vuex'
import { throttle } from '@/utils/frequency';
import { label } from "@/api/interface/data";
export default {
   props: {
      selectedMember: { type: Array },
      disabled: { type: Boolean, default: false },
      name: { type: String, default: '' }
   },
   data() {
      return {
         nickName: '',
         memberList: [],
      }
   },
   updated() {
      this.$emit('emitMemberList', this.memberList)
   },
   computed: {
      ...mapState(['hotelInfo'])
   },
   methods: {
      // 获取(回显)已选中用户
      getSelectedMember() {
         this.memberList = this.selectedMember
      },
      // 获取用户列表
      getMemberList(callback) {
         const url = label.memberListToGroup
         const param = { keyword: this.nickName }
         if (this.name === 'issuingCenter') {
            param.companyId = this.hotelInfo.storeId
         }else {
            const { accountType } = JSON.parse(sessionStorage.getItem('userInfo') || '{}')
            if (accountType === 'HOTEL' || accountType === 'PLATFORM') param.hotelId = this.hotelInfo.id
            else param.companyId = this.hotelInfo.storeId
         }
         this.$axios.post(url, param, 'json').then(res => {
            if (!res.success) return this.memberList.length = 0
            const memberList = res.records.map(item => ({ value: item.nickName, id: item.id, companyId: item.companyId }))
            callback && callback(memberList)
         })
      },
      // 取消选中用户
      closeTag(tag) {
         this.memberList.splice(this.memberList.indexOf(tag), 1)
      },
      // 搜索用户列表
      searchMembers(queryString, cb) {
         if (this.disabled) return
         throttle(() => this.getMemberList((memberList) => cb(memberList)), 500)
      },
      // 选中当前用户
      selectMember(val) {
         this.memberList.push(val)
      },
      // 重置属性
      reset() {
         this.memberList.length = 0
         this.nickName = ''
      }
   },
}
</script>
<style lang="scss" scoped></style>
