/*
* 控制事件触发频率
* */

/*
 * 函数节流
 * callback *回调函数
 * delay *延迟执行时间
*/
export const throttle = (() => {
   let flag = true
   return function (callback, delay) {
      if (flag) {
         setTimeout(() => {
            callback()
            flag = true
         }, delay)
      }
      flag = false
   }
})()

/*
 * 函数防抖
 * callback *回调函数
 * delay *延迟执行时间
*/
export const debounce = (() => {
   let timer = null
   return function (callback, delay) {
      if (timer !== null) {
         clearTimeout(timer)
      }
      timer = setTimeout(() => {
         callback()
      }, delay)
   }
})()

export default {
   debounce,
   throttle,
}
