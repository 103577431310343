import { render, staticRenderFns } from "./searchUser.vue?vue&type=template&id=2958cd0a&scoped=true&"
import script from "./searchUser.vue?vue&type=script&lang=js&"
export * from "./searchUser.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2958cd0a",
  null
  
)

export default component.exports