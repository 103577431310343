<template>
   <section class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>会员标签</el-breadcrumb-item>
            <el-breadcrumb-item to="/tag_group">标签群组</el-breadcrumb-item>
            <el-breadcrumb-item>{{action === 'edit' ? "编辑" : "新增"}}群组</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-page-header @back="goBack" :content="`${action === 'edit' ? '编辑 ' : '新增'}群组`"></el-page-header>
      <el-main class="content-box">
         <el-row class="condition">
            <el-form ref="form" :model="ruleForm" :rules="rules">
               <el-form-item label="群组名称" prop="groupName">
                  <el-input class="width-300 m-right-10" v-model="ruleForm.groupName" placeholder="群组名称" clearable :disabled="action === 'look'"></el-input>
               </el-form-item>
               <el-form-item label="群组类型">
                  <el-select v-model="ruleForm.groupUnder" class="width-300" :placeholder="$t('msg.select')" :disabled="action === 'look'">
                     <el-option
                         v-for="(label, value) in dictData['member-group-type']"
                         :key="value"
                         :label="label"
                         :value="value">
                     </el-option>
                  </el-select>
               </el-form-item>
               <el-form-item label="指定用户">
                  <el-button @click="handleUser">管理</el-button><span v-if="memberList.length > 0" class="tips">已指定</span>
               </el-form-item>
               <el-form-item label="条件管理">
                  <el-button @click="visibleCondition = true">管理</el-button><span v-if="ruleForm.propStr || ruleForm.touchStr" class="tips">已设置</span>
               </el-form-item>
               <el-form-item label="适用酒店" v-if="accountType === 'PLATFORM'">
                  <el-radio-group v-model="ruleForm.applicableType">
                     <el-radio label="1">全部酒店</el-radio>
                     <el-radio label="2">指定酒店</el-radio>
                  </el-radio-group>
                  <el-button class="m-left-10" size="mini" v-if="ruleForm.applicableType === '2'" @click="getHotelList">选择酒店</el-button>
               </el-form-item>
               <el-form-item v-if="accountType === 'PLATFORM' && ruleForm.applicableType === '2'">
                  <el-table
                      :data="ruleForm.hotelList"
                      highlight-current-row border
                      size="mini"
                      style="height: 200px; width: 500px; overflow-y: auto"
                      @selection-change="changeSelection">
                     <el-table-column width="60" label="序号">
                        <template slot-scope="scope">
                           {{scope.$index + 1}}
                        </template>
                     </el-table-column>
                     <el-table-column property="hotelName" label="酒店名称"></el-table-column>
                  </el-table>
               </el-form-item>
            </el-form>
            <el-row class="m-top-50" v-if="action !== 'look'">
               <el-button class="bg-gradient" type="primary" round @click="handlePreview">预览</el-button>
               <el-button class="bg-gradient" type="primary" round @click="handleSave">保存</el-button>
            </el-row>
         </el-row>
         <el-row class="user">
            <!-- 查询框 -->
            <!-- <el-row class="search-box">
               <el-row class="search-row">
                  <el-row class="search-item">
                     <el-select class="width-120 m-right-5" v-model="appCategory"  :placeholder="$t('msg.select')">
                        <el-option
                            v-for="item in searchOpt"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                     </el-select>
                     <el-input placeholder="请输入内容"></el-input>
                  </el-row>
                  <el-button class="bg-gradient"  type="primary" icon="el-icon-search"  @click="handleQuery"><span v-text="$t('msg.search')">搜索</span></el-button>
                  <el-button   type="primary" icon="el-icon-refresh-right"  plain @click="handleReset"><span v-text="$t('msg.reset')">重置</span></el-button>
               </el-row>
            </el-row>-->
            <el-table
                  :data="tableData"
                  style="width: 100%"
                  :stripe="true">
               <el-table-column label="序号" width="60">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column label="头像" min-width="70">
                  <template slot-scope="scope">
                     <img v-if="scope.row.headPortrait" :src="scope.row.headPortrait" width="40" height="40" class="suffix-cursor v-align-middle"/>
                     <img v-else src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" width="40" height="40" class="suffix-cursor v-align-middle" alt="">
                  </template>
               </el-table-column>
               <el-table-column prop="userName" label="用户名" min-width="70"></el-table-column>
               <el-table-column prop="nickName" label="昵称" min-width="50"></el-table-column>
               <el-table-column prop="mobile" label="手机号" min-width="140"></el-table-column>
               <el-table-column label="性别">
                  <template slot-scope="scope">
                     {{ scope.row.gender | filterGender(that) }}
                  </template>
               </el-table-column>
               <el-table-column prop="email" label="邮箱"></el-table-column>
               <el-table-column prop="createTime" label="创建时间" min-width="70"></el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
         </el-row>
      </el-main>
      <!-- 用户搜索 -->
      <el-dialog title="用户搜索" :close-on-click-modal="false" :visible.sync="visibleSearch" width="550px" >
         <search-user ref="searchUser" @emitMemberList="getSelectMember" :selectedMember="memberList"/>
         <div slot="footer" class="dialog-footer">
            <el-button size="medium" @click="visibleSearch = false">取 消</el-button>
            <el-button size="medium" type="primary" @click="saveMember">确 定</el-button>
         </div>
      </el-dialog>
      <!-- 条件管理 -->
      <el-dialog title="条件管理" :visible.sync="visibleCondition" :close-on-click-modal="false" width="900px">
         <condition @condition="condition" @close="visibleCondition = false"/>
      </el-dialog>
      <!-- 选择酒店 -->
      <el-dialog
          title="选择酒店"
          :visible.sync="visibleHotel"
          width="600px">
         <el-table
             ref="multipleTable"
             :data="hotelData"
             tooltip-effect="dark"
             style="height: 500px; overflow-y: auto"
             @selection-change="changeSelection">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column width="60" label="序号">
               <template slot-scope="scope">
                  {{scope.$index + 1}}
               </template>
            </el-table-column>
            <el-table-column property="hotelName" label="酒店名称"></el-table-column>
         </el-table>
         <span slot="footer">
            <el-button @click="visibleHotel = false">取 消</el-button>
            <el-button type="primary" @click="saveHotels()">确 定</el-button>
         </span>
      </el-dialog>
   </section>
</template>
<script>
import condition from '@/components/local/conditionMan'
import searchUser from '@/components/local/searchUser'
import { mapState } from 'vuex'
import { urlObj } from '@/api/interface'
import { getDict } from "@/common/js/common";
import { label} from '@/api/interface/data'
export default {
   data(){
      return{
         that: this,
         password: '',
         conditionList: [],
         ruleForm: {
            groupName: '',
            groupUnder: 'FIXED',
            applicableType: '1',
            memberIdList: [],
            hotelList: [],
            propStr: '',
            touchStr: '',
         },
         rules: {
            groupName: [{ required: true, message: '请输入名称', trigger: 'blur' }]
         },
         searchOpt: {},
         visibleSearch: false,
         tableData: [],
         memberList: [],
         limit: 1,
         page: 1,
         total: 0,
         groupId: '',
         action: '',
         visibleCondition: false,
         accountType: '',
         visibleHotel: false,
         hotelData: [],
         selectHotels: []
      }
   },
   computed: {
      ...mapState(['hotelInfo', 'dictData']),
   },
   components: {
      condition, searchUser
   },
   mounted() {
      getDict(['gender', 'member-group-type'])
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.action = this.$route.query.action
      this.accountType = JSON.parse(sessionStorage.getItem('userInfo') || '{}').accountType
      if (this.action === 'edit' || this.action === 'look') this.echoTagGroup()
   },
   beforeDestroy() {
      sessionStorage.removeItem('groupInfo')
   },
   methods: {
      // 回显群组条件
      echoTagGroup(){
         const groupInfo = JSON.parse(sessionStorage.getItem('groupInfo'))
         this.groupId = groupInfo.id
         this.ruleForm.groupName = groupInfo.groupName
         this.ruleForm.groupUnder = groupInfo.groupUnder
         this.ruleForm.propStr = groupInfo.propStr
         this.ruleForm.touchStr = groupInfo.touchStr
         this.ruleForm.applicableType = groupInfo.applicableType
         this.ruleForm.applicableType === '2' && this.lookHotels()
         this.getMemberList(async (memberList) => {
            const res = await this.getShiftAddMemberIdList();
            if (!res.success) return
            const memberIdList = res.records
            this.memberList = memberList.filter(item => memberIdList.includes(item.id))
            this.ruleForm.memberIdList = this.memberList.map(item => item.id)
            this.handlePreview()
         })
      },
      // 获取用户列表
      getMemberList(callback) {
         const url = label.memberListToGroup
         const param = { keyword: this.nickName }
         const { accountType } = JSON.parse(sessionStorage.getItem('userInfo') || '{}')
         if (accountType === 'HOTEL' || accountType === 'PLATFORM') param.hotelId = this.hotelInfo.id
         else param.companyId = this.hotelInfo.storeId
         this.$axios.post(url, param, 'json').then(res => {
            if (!res.success) return this.memberList.length = 0
            const memberList = res.records.map(item => ({ value: item.nickName, id: item.id }))
            callback && callback(memberList)
         })
      },
      // 处理会员
      handleUser() {
         this.visibleSearch = true
         if (this.action === 'edit') this.$nextTick(() => this.$refs.searchUser.getSelectedMember())
      },
      // 获取群组会员列表
      getShiftAddMemberIdList() {
         return new Promise((resolve, reject) => {
            const url = urlObj.shiftAddMemberIdList
            const param = { groupId: this.groupId }
            this.$axios.get(url, param).then(res => resolve(res))
                .catch(err => reject(err))
         })
      },
      // 获取选中会员
      getSelectMember(memberList) {
         this.memberList = memberList
      },
      // 保存会员
      saveMember() {
         this.visibleSearch = false
         this.ruleForm.memberIdList = this.memberList.map(item => item.id)
      },
      // 条件参数
      condition(propStr, touchStr) {
         this.ruleForm.propStr = propStr
         this.ruleForm.touchStr = touchStr
         this.visibleCondition = false
      },
      // 保存群组条件
      handleSave(){
         this.$refs.form.validate(valid => {
            if (!valid) return
            let url = label.addTagGroup
            const param = {
               companyId: this.hotelInfo.storeId,
               groupName: this.ruleForm.groupName,
               groupUnder: this.ruleForm.groupUnder,
               memberIdList: this.ruleForm.memberIdList,
               hotelList: JSON.stringify(this.ruleForm.hotelList.map(item => ({ hotelId: item.hotelId, companyId: item.companyId }))),
            }
            this.accountType === 'PLATFORM' && (param.applicableType = this.ruleForm.applicableType)
            this.ruleForm.propStr && (param.propStr = this.ruleForm.propStr)
            this.ruleForm.touchStr && (param.touchStr = this.ruleForm.touchStr)
            if (this.accountType !== 'PLATFORM' || this.ruleForm.applicableType === '1') delete param.hotelList
            if (this.action === 'edit'){
               url = label.editTagGroup
               param.id = this.groupId
            }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: `${ this.action === 'add' ? '添加' : '编辑' }成功！`,
                     type: 'success'
                  })
                  this.$router.go(-1)
               }
            })
         })
      },
      // 预览群组
      handlePreview(){
         const url = label.previewTagGroup + `?limit=${this.limit}&page=${this.page}`
         const param = { companyId: this.hotelInfo.storeId, memberIdList: this.ruleForm.memberIdList }
         this.ruleForm.propStr && (param.propStr = this.ruleForm.propStr)
         this.ruleForm.touchStr && (param.touchStr = this.ruleForm.touchStr)
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               this.total = res.total
               this.tableData = res.records
            }
         })
      },
      // 改变每页数
      pageChange(num){
         this.limit = num
         this.handlePreview()
      },
      // 改变当前页
      handlePaging(num){
         this.page = num
         this.handlePreview()
      },
      // 获取酒店列表
      getHotelList() {
         this.visibleHotel = true
         if (this.hotelData.length > 0) return
         const url = urlObj.getHotelList
         const userInfo = JSON.parse(sessionStorage.getItem('userInfo') || '{}')
         const param = { hotelId: userInfo.extra && userInfo.extra.hotelId || '', companyId: userInfo.storeId || '' }
         this.$axios.post(url, param, 'json').then(res => {
            if (!res.success) return
            this.hotelData = res.records.map(item => ({ id: item.id, hotelName: item.hotelName, companyId: item.storeId }))
            this.$nextTick(() => {
               this.ruleForm.hotelList.forEach(i => this.$refs.multipleTable.toggleRowSelection(this.hotelData.find(r => i.hotelId === r.id)))
            })
         })
      },
      // 选择酒店
      changeSelection(val) {
         this.selectHotels = val
      },
      // 保存酒店
      saveHotels() {
         this.visibleHotel = false
         this.ruleForm.hotelList = this.selectHotels.map(item => ({ hotelId: item.id, hotelName: item.hotelName, companyId: item.companyId }))
      },
      // 查看适用酒店
      lookHotels() {
         const url = label.getHotelListByGroupId
         const param = { groupId: this.groupId }
         this.$axios.post(url, param, 'json').then(async res => {
            if (!res.success) return
            this.ruleForm.hotelList = res.records.map(item => ({ hotelId: item.id, hotelName: item.hotelName, companyId: item.companyId }))
         })
      },
      // 返回上一页
      goBack(){
         this.$router.go(-1)
      },
   },
   filters: {
      filterGender(val, that) {
         if (val && that.dictData['gender']) return that.dictData['gender'][val] || ''
      }
   }
}
</script>
<style scoped lang="scss">
.cont{
   min-width: 1120px;
   ::v-deep .el-form-item__error{ margin-left: 77px }
   .tips{ color: #0c7ffd; margin-left: 6px; font-size: 12px }
}
</style>
